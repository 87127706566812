<template>
  <div class="users">
    <h3><span @click="menu(!desktop)">User Groups</span><span @click="addGroup()"></span>
    </h3>
    <div class="group_list">
      <div class="group_header">
        <div>Group Name</div>
        <div>Description</div>
        <div></div>
      </div>
      <div class="list_body" v-for="(group,index) in groups" :key="index">
        <div>{{ group.name }}</div>
        <div>{{ group.desc }}</div>
        <div><span class="edit" @click="editGroup(index)"></span><span class="delete" @click="deleteGroup(index)"></span></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits } from 'vue';
const props = defineProps({groups: Object, desktop: Boolean});
const emit = defineEmits(['selected', 'addGroup', 'appear']);
let desktop = toRef(props, 'desktop');

function menu(i) {
  emit('appear',i);
}
function editGroup(i) {
  emit('selected',i);
}
function deleteGroup(i) {
  emit('del',i);
}
function addGroup(i) {
  emit('addGroup',i);
}
</script>

<style lang="scss" scoped>
  .users{
    .group_list{
      padding: 20px 20px 0 20px;
      .group_header{
        font-weight: 500;
      }
      >div{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: #444;
        border-bottom: 1px solid #bcbcbc;
        >div{
          padding: 8px 0;
          display: flex;
          align-items: center;
        }
        >div:nth-child(1){
          width: 25%;
        }
        >div:nth-child(2){
          width: 63%;
        }
        >div:nth-child(3){
          width: 12%;
          justify-content: center;
          .edit,.delete{
            width: 30px;
            height: 30px;
            background-repeat: no-repeat;
            background-size: 100%;
            background-position: center;
            cursor: pointer;
          }
          .edit{
            background-image: url(../../../public/img/edit.svg);
          }
          .delete{
            margin-left: 15px;
            background-image: url(../../../public/img/delete.svg);
          }
        }
      }
    }
    h3{
      height: 60px;
      line-height: 60px;
      margin: 0px;
      padding: 0 0 0 20px;
      background-color: #f2f2f2;
      position: relative;
      span:last-child{
        height: 30px;
        width: 30px;
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
        background-repeat: no-repeat;
        background-size: 24px;
        background-position: center;
        background-image: url(../../../public/img/plus_gray.svg);
        cursor: pointer;
      }
    }
  }
</style>